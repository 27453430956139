import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderColor: 'black'
  },
});

const InvoiceItemsTable = ({ invoice }) => {
  var net_amount=0
  var total_amount=0
  var discount=0
  var mpl_share=0
  var franchise_share=0
  console.log(invoice)
  invoice.map((i)=>{
    net_amount=net_amount+i.net_amount
    total_amount=total_amount+i.total_amount
    discount=discount+i.discount
    mpl_share=mpl_share+i.mpl_share
    franchise_share=franchise_share+i.franchise_share
  })

 return <View style={styles.tableContainer}>
    <InvoiceTableRow items={invoice} />
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%",
      height: 20,
      fontSize: 9,
      marginTop:10,
      fontStyle: 'bold'
    }} key={"221"}>
      <Text style={{

        width: '55%',
        textAlign: 'left',
        height: '100%',
        
        fontSize: 9,
      }}>

      </Text>
      <Text style={{

        width: '30%',
        textAlign: 'left',
        borderColor: 'black',
        borderWidth: 0.5,
        height: '100%',
        fontSize: 9,
        paddingLeft:5,
        paddingTop:5


      }}>{"Total Amount"}</Text>
      <Text style={{

        width: '15%',
        height: '100%',
        textAlign: 'right',
        borderColor: 'black',
        borderWidth: 0.5,
        backgroundColor: "#eeeeee",
        fontSize:9 ,
        borderRightWidth: 1,
        paddingTop:5,
        paddingRight:5
        
      }}>{total_amount.toFixed(2)+" Rs/-"}</Text>

    </View>
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%",
      height: 20,
      fontSize: 9,
      fontStyle: 'bold'
    }} key={"221"}>
      <Text style={{

        width: '55%',
        textAlign: 'left',
        height: '100%',
        
        fontSize: 9,
      }}>

      </Text>
      <Text style={{

        width: '30%',
        textAlign: 'left',
        borderColor: 'black',
        borderWidth: 0.5,
        height: '100%',
        paddingLeft:5,
        paddingTop:5,
        fontSize: 9,
        


      }}>{"Discount Given to Patient"}</Text>
      <Text style={{

        width: '15%',
        height: '100%',
        textAlign: 'right',
        borderColor: 'black',
        borderWidth: 0.5,
        backgroundColor: "#eeeeee",
        fontSize: 9,
        borderRightWidth: 1,
        paddingTop:5,
        paddingRight:5
        

      }}>{discount.toFixed(2)+" Rs/-"}</Text>

    </View>
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%",
      height: 20,
      fontSize: 9,
      fontStyle: 'bold'
    }} key={"221"}>
      <Text style={{

        width: '55%',
        textAlign: 'left',
        height: '100%',
        
        fontSize: 9,
      }}>

      </Text>
      <Text style={{

        width: '30%',
        textAlign: 'left',
        borderColor: 'black',
        borderWidth: 0.5,
        height: '100%',
        paddingLeft:5,
        paddingTop:5,
        fontSize: 9,
        


      }}>{"Net Amount"}</Text>
      <Text style={{

        width: '15%',
        height: '100%',
        textAlign: 'right',
        borderColor: 'black',
        borderWidth: 0.5,
        backgroundColor: "#eeeeee",
        fontSize: 9,
        borderRightWidth: 1,
        paddingTop:5,
        paddingRight:5
        

      }}>{(total_amount-discount).toFixed(2)+" Rs/-"}</Text>

    </View>
   
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%",
      height: 20,
      fontSize: 9,
      fontStyle: 'bold'
    }} key={"221"}>
      <Text style={{

        width: '55%',
        textAlign: 'left',
        height: '100%',
        
        fontSize: 9,
      }}>

      </Text>
      <Text style={{

        width: '30%',
        textAlign: 'left',
        borderColor: 'black',
        borderWidth: 0.5,
        height: '100%',
        paddingLeft:5,
        paddingTop:5,
        fontSize: 9,
        


      }}>{"FSLS Share"}</Text>
      <Text style={{

        width: '15%',
        height: '100%',
        textAlign: 'right',
        borderColor: 'black',
        borderWidth: 0.5,
        backgroundColor: "#eeeeee",
        fontSize: 9,
        borderRightWidth: 1,
        paddingTop:5,
        paddingRight:5
        

      }}>{mpl_share.toFixed(2)+" Rs/-"}</Text>

    </View>
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%",
      height: 20,
      fontSize: 9,
      fontStyle: 'bold'
    }} key={"221"}>
      <Text style={{

        width: '55%',
        textAlign: 'left',
        height: '100%',
        
        fontSize: 9,
      }}>

      </Text>
      <Text style={{

        width: '30%',
        textAlign: 'left',
        borderColor: 'black',
        borderWidth: 0.5,
        height: '100%',
        paddingLeft:5,
        paddingTop:5,
        fontSize: 9,
        


      }}>{"Franchise Share"}</Text>
      <Text style={{

        width: '15%',
        height: '100%',
        textAlign: 'right',
        borderColor: 'black',
        borderWidth: 0.5,
        backgroundColor: "#eeeeee",
        fontSize: 9,
        borderRightWidth: 1,
        paddingTop:5,
        paddingRight:5
        

      }}>{franchise_share.toFixed(2)+" Rs/-"}</Text>
     
    </View>
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%",
      height: 20,
      fontSize: 9,
      fontStyle: 'bold'
    }} key={"221"}>
      <Text style={{

        width: '55%',
        textAlign: 'left',
        height: '100%',
        
        fontSize: 9,
      }}>

      </Text>
      <Text style={{

        width: '30%',
        textAlign: 'left',
        borderColor: 'black',
        borderWidth: 0.5,
        height: '100%',
        paddingLeft:5,
        paddingTop:5,
        fontSize: 9,
        


      }}>{"Net Revenue (Franchise)"}</Text>
      <Text style={{

        width: '15%',
        height: '100%',
        textAlign: 'right',
        borderColor: 'black',
        borderWidth: 0.5,
        backgroundColor: "#eeeeee",
        fontSize: 9,
        borderRightWidth: 1,
        paddingTop:5,
        paddingRight:5
        

      }}>{net_amount.toFixed(2)+" Rs/-"}</Text>

    </View>
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
      width: "100%",
      height: 20,
      fontSize: 9,
      fontStyle: 'bold'
    }} key={"221"}>
      <Text style={{

        width: '55%',
        textAlign: 'left',
        height: '100%',
        
        fontSize: 9,
      }}>

      </Text>
    
</View>
  </View>
}

export default InvoiceItemsTable