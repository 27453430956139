import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import Invoice from './PDF/Invoice.js'

import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { DatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from '@date-io/date-fns';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';




import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import RefreshIcon from '@material-ui/icons/Refresh';

import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';






function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}


const columns =
  [{ id: 'invoice_no', label: 'Invoice ID' },
  { id: 'patient_name', label: 'Patient Name' },
  { id: 'invoice_date', label: 'Date' },
  { id: 'total_amount', label: 'Total Amount' },
  { id: 'discount', label: 'Discount' },
  { id: 'net_amount', label: 'Net Amount' },
  { id: 'mpl_share', label: 'FSLS Status' },
  { id: 'franchise_share', label: 'Franchise Share' }
  ]
function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
function to24Hours(d) {
  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());
  return h + ":" + m + ":" + s;
}
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return [hours, minutes, 1];
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export default function Summary() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')


  const [selected, setSelected] = React.useState([])

  const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)

  const [OpenOfficialCovidTemplate, setOfficialCovidTemplate] = React.useState(false)

  const [patient_invoice, setPatientInvoice] = React.useState(false)
  date.setHours(0, 0, 0)
  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);

  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)

  const [covidButton, setCovidButton] = React.useState(false)

  const [panelList, setpanelList] = React.useState([])
  const [laborder, setLabOrder] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const [selectBranch, setSelectBranch] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All'
  });
  // Booking Filter
  const [BookedBy, setBookedBy] = React.useState([])
  const [SelectedBookedBy, setSelectedBookBy] = React.useState("Select")

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    var vSearchStr = {
      "User_ID": localStorage.getItem('user_id'),
      "Menu_Option": "Franchise Share Summary"
    }
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebCheckUserStatusActiveAccess", {
      method: "POST",
      'Content-Type': "application/json",
      body: JSON.stringify(vSearchStr)
    }).then(res => res.json()).then((res) => {
      if (res[0].Option_Status == "Authorized") {

      } else {
        localStorage.clear()
        window.location.href = "https://invoicing.fslabs.com.pk/login"
      }
    })
    var branchID = localStorage.getItem('branch_id')

    var SearchFormData = { from_date: from.toISOString(), to_date: to.toISOString(), branch_id: parseInt(branchID) }
    var vSearchStr = JSON.stringify(SearchFormData);

    var url = "https://eviewer.fslabs.com.pk:5443/4DACTION/WebCallingUpFranchiseSales"

    fetch(url,{
      method: "POST",
      body: vSearchStr
    }).then((res) => res.json())
      .then((result) => {
        var from_date = (from.ddmmyyy()) + " " + (from.getHours() + ":" + from.getMinutes() + ":" + from.getSeconds())
        var to_date = (to.ddmmyyy()) + " " + (to.getHours() + ":" + to.getMinutes() + ":" + to.getSeconds())
        var the_rows=[]
    
        var invoiceObj={}
        result.map((data) => {
       
            var invoice_date = new Date(data.invoice_date).ddmmyyy()

            invoiceObj[data.invoice_no]={
              ...data,invoice_date:invoice_date,
              discount: (invoiceObj[data.invoice_no]==undefined ? 0: invoiceObj[data.invoice_no].discount) + data.discount,

              franchise_share: (invoiceObj[data.invoice_no]==undefined ? 0 : invoiceObj[data.invoice_no].franchise_share) + data.franchise_share,

              mpl_share: (invoiceObj[data.invoice_no]==undefined ? 0 : invoiceObj[data.invoice_no].mpl_share) + data.mpl_share,

              net_amount: (invoiceObj[data.invoice_no]==undefined ? 0 : invoiceObj[data.invoice_no].net_amount) + data.net_amount,

              total_amount: (invoiceObj[data.invoice_no]==undefined ? 0 :invoiceObj[data.invoice_no].total_amount) + data.total_amount,

            }
           
         
          })

          Object.keys(invoiceObj).map((key)=>{

            the_rows.push(invoiceObj[key])
          })

          setRows(the_rows)
          setOrg_Rows(the_rows)
          setLoading(false)
          setSelected({
            from: from_date,
            to: to_date,
            branch:localStorage.getItem("branch_name"),
            items: the_rows
          })
        })
     
  


  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <>
      <Paper className={classes.root}>
        <div style={{ padding: '1em', textAlign: 'center' }}>
          {localStorage.getItem('org_branch_id') == '0' ? <h3 style={{ color: 'red' }}>Branch Selection will change the Booking, Invoicing and Reporting Branch</h3> : null}
        </div>
        <GridContainer style={{ padding: '1em' }}>
          <GridItem xs={6} sm={6} md={3} lg={3}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                inputVariant="outlined"
                label="From"
                ampm={false}
                minDate={new Date().setMonth(new Date().getMonth() - 1)}
                format="dd/MM/yyyy HH:mm"
                value={from}
                maxDate={to}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => {
                  setFrom(date)
                }}
              />
            </MuiPickersUtilsProvider>
          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }

          <GridItem xs={6} sm={6} md={3} lg={3}>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                inputVariant="outlined"
                label="To"
                ampm={false}
                format="dd/MM/yyyy HH:mm"
                maxDate={new Date()}
                value={to}
                minDate={from}
                InputAdornmentProps={{ position: "start" }}
                onChange={date => setTo(date)}
              />
            </MuiPickersUtilsProvider>

          </GridItem>

       
          
          <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto', flex: "1", flexDirection: "column" }} >

            <Button variant="contained" color="primary" disableElevation disabled={loading}
              style={{
                marginBottom: 5
              }}
              onClick={() => {
                if (to == "Invalid Date" || from == "Invalid Date") {
                  alert("Invalid Date")
                  return
                }
                setPage(0)
                setLoading(true)
                var branchID = localStorage.getItem('branch_id')

                var SearchFormData = { from_date: from.toISOString(), to_date: to.toISOString(), branch_id: parseInt(branchID) }
                var vSearchStr = JSON.stringify(SearchFormData);
            
                var url = "https://eviewer.fslabs.com.pk:5443/4DACTION/WebCallingUpFranchiseSales"
            
                fetch(url,{
                  method: "POST",
                  body: vSearchStr
                }).then((res) => res.json())
                  .then((result) => {
                    var from_date = (from.ddmmyyy()) + " " + (from.getHours() + ":" + from.getMinutes() + ":" + from.getSeconds())
                    var to_date = (to.ddmmyyy()) + " " + (to.getHours() + ":" + to.getMinutes() + ":" + to.getSeconds())
                    var the_rows=[]
                    var invoiceObj={}
                    result.map((data) => {
       
                      var invoice_date = new Date(data.invoice_date).ddmmyyy()
          
                      invoiceObj[data.invoice_no]={
                        ...data,invoice_date:invoice_date,
                        discount: (invoiceObj[data.invoice_no]==undefined ? 0: invoiceObj[data.invoice_no].discount) + data.discount,
          
                        franchise_share: (invoiceObj[data.invoice_no]==undefined ? 0 : invoiceObj[data.invoice_no].franchise_share) + data.franchise_share,
          
                        mpl_share: (invoiceObj[data.invoice_no]==undefined ? 0 : invoiceObj[data.invoice_no].mpl_share) + data.mpl_share,
          
                        net_amount: (invoiceObj[data.invoice_no]==undefined ? 0 : invoiceObj[data.invoice_no].net_amount) + data.net_amount,
          
                        total_amount: (invoiceObj[data.invoice_no]==undefined ? 0 :invoiceObj[data.invoice_no].total_amount) + data.total_amount,
          
                      }
                     
                   
                    })
          
                    Object.keys(invoiceObj).map((key)=>{
          
                      the_rows.push(invoiceObj[key])
                    })
                     
                      setRows(the_rows)
                      setOrg_Rows(the_rows)
                      setLoading(false)
                      setSelected({
                        from: from_date,
                        to: to_date,
                        branch:localStorage.getItem("branch_name"),
                        items: the_rows
                      })
                    })

              }}
            >
              Seach<SearchIcon />
            </Button>
            <Button variant="contained" color="secondary" disableElevation disabled={loading}
              onClick={() => {
                setShow(true)
              }} >
              Export Summary
                  </Button>
          </GridItem>
        </GridContainer>
       
        {loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          var status_color = value == 'Just Booked' ? 'brown' : value == 'Only Phlebotomy Done' ? 'red' :
                            value == 'Lying Pending Results' ? 'purple' : value == 'Results Done But not Signed' ? 'blue' : value == 'Results Ready' ? 'green' : ''

                          return (
                            <>
                              {status_color.trim() != '' ?
                                <TableCell key={column.id} align={column.align} style={{ backgroundColor: status_color, color: 'white' }} >
                                  <i><b>{value}</b></i>
                                </TableCell>
                                : <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }

        <GridContainer>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={show}
            TransitionComponent={Transition}
            keepMounted
            maxWidth={'xl'}
            fullWidth={true}
            onClose={() => setShow(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <DialogTitle
              id="classic-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <h3><b>FRANCHISE BOOKING SUMMARY</b></h3>

            </DialogTitle>
            <DialogContent
              id="classic-modal-slide-description"
              className={classes.modalBody}

            >
              <center>
                <Fragment>
                  {
                    show ? <PDFViewer width="1000" height="1000" className="app" >
                      <Invoice invoice={selected} />
                    </PDFViewer> : null
                  }
                </Fragment>
              </center>
            </DialogContent>
            <DialogActions className={classes.modalFooter}>

              <Button
                onClick={() => setShow(false)}
                color="danger"
                simple
              >
                <b>Close</b>
              </Button>
            </DialogActions>
          </Dialog>
        </GridContainer>
      </Paper>


    </>
  );
}
